import './index.css'

const PlacementAssistance = () => (
  <div id="placementAssistance" className="placement-assistance-bg-container">
    <div className="placement-assistance-container">
      <h1 className="placement-container-heading">100% Placement Assistance</h1>
      <h1 className="placement-assistance-title">Resume Building</h1>
      <p className="placement-assistance-description">
        impactful resumes showcasing skills and achievements.
      </p>
      <h1 className="placement-assistance-title">Career Counselling</h1>
      <p className="placement-assistance-description">
        Guidance for career decisions, goals, and opportunities.
      </p>
      <h1 className="placement-assistance-title">Jobs</h1>
      <p className="placement-assistance-description">
        Seeking skilled professionals for exciting career opportunities.
      </p>
    </div>
  </div>
)

export default PlacementAssistance
